<template>
  <div class="home">
        <MainBanner :language = "lenguajeactual"/>
            <main id="main">
              <AsyncComponent />
            </main><!-- End #main -->
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from './Home/MainBanner.vue'
import LoadingComponent from '../components/Loading.vue'

// Async component with options
const AsyncComponent = () => ({
  component: import('./Home/AsyncHome.vue'),
  loading: LoadingComponent,
  delay: 0,
  timeout: 3000
})

export default {
  name: 'Home',
  components: {
    AsyncComponent, MainBanner
  },
  data () {
    return {
      lenguajeactual: this.getlanguage()
    }
  },
  props: {
    languaje: String
  },
  created () {
    this.track()
  },
  methods: {
    getlanguage () {
      console.log(window.location.hash)
      if (!(window.location.hash.indexOf('es') === -1)) {
        console.log('Dice Es Home')
        return 'es'
      }
      if (!(window.location.hash.indexOf('en') === -1)) {
        console.log('Dice En Home')
        return 'en'
      } else {
        console.log('Lenguaje Automático')
        return navigator.language
      }
    },
    track () {
      this.$gtag.pageview({ page_path: '/home' })
    }
  }
}
</script>

<style>

</style>
